<template>
  <div class="card">
    <div class="card-body">
      <iframe
        src="user-guide-medhop/index.html"
        frameborder="0"
        style="width: 100%; height: 605px"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error-1",
  mounted() {},
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "images/help/help-7.jpg";
    },
  },
};
</script>
